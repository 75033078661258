import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Indoor_Cameras/IN-6001_HD/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "IN-6001 HD Manual Lense Adjustment",
  "path": "/Indoor_Cameras/IN-6001_HD/Lense_Adjustment/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "INSTAR IN-6001 HD Indoor IP Camera Manual Lense Adjustment",
  "image": "./P_SearchThumb_IN-6001HD_Lense_Adjust.png",
  "social": "/images/Search/P_SearchThumb_IN-6001HD_Lense_Adjust.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-IN-6001HD_white.webp",
  "chapter": "Indoor Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='IN-6001 HD Manual Lense Adjustment' dateChanged='2017-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='INSTAR IN-6001 HD Indoor IP Camera Manual Lense Adjustment' image='/images/Search/P_SearchThumb_IN-6001HD_Lense_Adjust.png' twitter='/images/Search/P_SearchThumb_IN-6001HD_Lense_Adjust.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Innenkameras/IN-6001_HD/Objektivjustage/' locationFR='/fr/Indoor_Cameras/IN-6001_HD/Lense_Adjustment/' crumbLabel="Lense" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "manual-focus-adjustment-for-your-in-6001-hd",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#manual-focus-adjustment-for-your-in-6001-hd",
        "aria-label": "manual focus adjustment for your in 6001 hd permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Manual focus adjustment for your IN-6001 HD`}</h2>
    <p>{`When you receive your camera, we already adjusted the focus for the camera to be operational in a general surveillance scenario. For your IN-6001 HD this means, that they give you a sharp image for distances between 5 and 10m. If you need a different focus distance, just turn the camera lense clockwise or counterclockwise, until you have your scene in focus.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "899px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3ca8c36f44b32cfd4e014396159f9825/681f1/IN-6001_Lense-Adjustments.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB7ElEQVQoz2P49+/f////nzx5fPPG9Yc//j7/+ff379//UQFEzefPn69dvfzwzYcXn7//+PLp////DH/+/P3//39TQ424o79mSLJMZtuK45f/////5+8/uOY/f/78////5LHDGhY2UpltSiFpKX3z/v79ywCR6O7tVtTSVpOTUlFU6J0wAazhL1zz378g9rlzZyxsrRUV5NWUlZwDwr5+/8Hw5w/IkdOnTzXQ0dTR0ZaRkW5oqINrQNZ848Z1JwdbA10dBXl5G0uLjx8+QG1evHihuYV5aEiolrZWUVERVs2PHj10c3Px8/O3tbWxtLJ6/OQxVPOOHdutrCwzsgtDwqLS0tLggYQcYB8/fggKCggKDsvKK3Jxdbt06SIDxNRjx47a2Nr4BwTbOziHh4f//PkTWT+E8efPn8jIcGdXN1//IAsLqwMHDjBAJN6+fZOYENvY0LB3397Tp08jW4vs8kmT+mOio7Zs3bJ//753795CNf/9+6eqsmzt2jVovkWLrS2bN5QUF33/8QPiHAZI7E+ZOtHOzjokJLS1te3o0aNY/bx3357w8BA7O9uKysqFCxf9/PmD4c2bN8+ePbt588bBgwdPnDh54cL558+fo2l7/uz5u7fvXrx8ceTIoWPHjp09e+bWrZt//vwGAN5v+CaRD3pEAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3ca8c36f44b32cfd4e014396159f9825/e4706/IN-6001_Lense-Adjustments.avif 230w", "/en/static/3ca8c36f44b32cfd4e014396159f9825/d1af7/IN-6001_Lense-Adjustments.avif 460w", "/en/static/3ca8c36f44b32cfd4e014396159f9825/06569/IN-6001_Lense-Adjustments.avif 899w"],
              "sizes": "(max-width: 899px) 100vw, 899px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3ca8c36f44b32cfd4e014396159f9825/a0b58/IN-6001_Lense-Adjustments.webp 230w", "/en/static/3ca8c36f44b32cfd4e014396159f9825/bc10c/IN-6001_Lense-Adjustments.webp 460w", "/en/static/3ca8c36f44b32cfd4e014396159f9825/8bcb7/IN-6001_Lense-Adjustments.webp 899w"],
              "sizes": "(max-width: 899px) 100vw, 899px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3ca8c36f44b32cfd4e014396159f9825/81c8e/IN-6001_Lense-Adjustments.png 230w", "/en/static/3ca8c36f44b32cfd4e014396159f9825/08a84/IN-6001_Lense-Adjustments.png 460w", "/en/static/3ca8c36f44b32cfd4e014396159f9825/681f1/IN-6001_Lense-Adjustments.png 899w"],
              "sizes": "(max-width: 899px) 100vw, 899px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3ca8c36f44b32cfd4e014396159f9825/681f1/IN-6001_Lense-Adjustments.png",
              "alt": "IN-6001 HD Objektiv Justage",
              "title": "IN-6001 HD Objektiv Justage",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Fig. 1/2 Connect the camera to the power supply and the network cable. Open the web interface and turn the lense until you can see a clear and sharp picture.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      